.giftnow-headline {
  padding: 32px 0px;
  font-size: 16px;

  .ui.header {
    font-size: 40px;
  }
}

.contact-form {
  padding: 32px 0px;
}