body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ui.container.customer-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.content-section {
  margin-bottom: 3rem;
  width: 100%;
  flex: 1;
}
.faq-section {
  margin-top: auto;
  background-color: rgba(76, 76, 76, 0.05);
}

.ui.inverted.segment.footer-container {
  margin-top: 0px;
}

.auth-error-message.ui.message:last-child {
  text-align: center;
}
