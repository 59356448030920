// breakpoints.scss


$MAX_WIDTH: 1440px;
$LARGE_MIN_WIDTH: 1024px;
$MEDIUM_MIN_WIDTH: 720px;

/**
 * Design language breakpoint helpers
 */
 

@mixin screen-is-max-width{
  @media screen and (min-width: $MAX_WIDTH){
    @content;
  }
}

@mixin screen-is-large{
  @media screen and (min-width: $LARGE_MIN_WIDTH){
    @content;
  }
}

@mixin screen-is-medium{
  @media screen and (max-width: $LARGE_MIN_WIDTH - 1) and (min-width: $MEDIUM_MIN_WIDTH){
    @content;
  }
}

@mixin screen-is-small{
  @media screen and (max-width: $MEDIUM_MIN_WIDTH - 1){
    @content;
  }
}

@mixin screen-is-not-small{
  @media screen and (min-width: $MEDIUM_MIN_WIDTH){
    @content;
  }
}

@mixin screen-is-not-large{
  @media screen and (max-width: $LARGE_MIN_WIDTH - 1){
    @content;
  }
}



/**
 * Generic breakpoint helpers
 */

@mixin screen-is-larger-than($width){
  @media screen and (min-width: $width){
    @content;
  }
}

@mixin screen-is-smaller-than($width){
  @media screen and (max-width: $width){
    @content;
  }
}

@mixin screen-is-between($from, $to) {
  @media screen and (max-width: $to) and (min-width: $from){
    @content;
  }
}