@use 'src/breakpoints';


.header-container {
  display: flex;
  position: relative;
  align-items: center;
  @include breakpoints.screen-is-small {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }
  .logo {
    width: auto;
    position: absolute;
    top: 5px;
    @include breakpoints.screen-is-small {
      position: unset;
      top: 0px;
      display: flex;
      justify-content: center;
    }
  }
  .header-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .header-content {
    display: flex;
    justify-content: center;
    flex: 1;
    .ui.header {
      color: white;
    }
  }
}