.faq-container {
  padding-top: 32px;
  padding-bottom: 32px;
  .faq-content {

    .faq-item {
      padding-top: 24px;
      margin-bottom: 8px;
      border-top: 1px solid black;

      .faq-title {
        display: flex;

        .faq-question {
          margin-right: auto;
        }
      }

      .title.faq-title ~ .active.content {
        .faq-answer {
          padding-right: 1.18em;
          margin-bottom: 24px;
        }
      }

    }
  }
}